<template>
  <div>
    <a-form-item-rest>
      <template v-if="dataSource.length === 0">
        <div class="flex justify-center">
          <a-upload-dragger
            :file-list="fileList"
            :max-count="1"
            accept=".zip"
            :multiple="false"
            :before-upload="() => false"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon">
              <basic-icon
                name="icon-upload_black_24dp"
                class="text-4xl"
              />
            </p>
            <p class="ant-upload-text">
              点击或拖拽文件到此上传
            </p>
            <p class="px-2 ant-upload-hint">
              可直接将文件拖拽到此处进行上传，支持zip等文件上传，最大不超过5M
            </p>
          </a-upload-dragger>
        </div>
        <!-- 错误信息提醒 -->
        <div
          v-if="errorFileMsgList.length > 0"
          class="flex flex-wrap items-center pt-6"
        >
          压缩包文件:
          <div
            v-for="(item, index) in errorFileMsgList"
            :key="index"
            class="text-red-500"
          >
            {{
              `${item.filename}${
                index === errorFileMsgList.length - 1 ? '' : '、'
              }`
            }}
          </div>
          &nbsp;&nbsp;等文件过大
        </div>
        <!-- 上传成功文件提醒,不需要显示了 -->
        <div
          v-if="uploadSuccessMsgList.length > 0 && false"
          class="flex flex-wrap items-center pt-6"
        >
          已成功上传压缩包，包含文件：
          <div
            v-for="(item, index) in uploadSuccessMsgList"
            :key="index"
          >
            {{
              `${item.filename}${
                index === uploadSuccessMsgList.length - 1 ? '' : '、'
              }`
            }}
          </div>
          &nbsp;&nbsp;等文件
        </div>
        <Divider class="mt-10" />
        <div class="flex justify-between mb-2 text-red-500">
          上传前请确认：
          <span
            v-if="Boolean(props.downLoadUrl)"
            class="text-blue-500 cursor-pointer"
            @click="
              downloadFile(props.downLoadUrl, {}, props.excelName, 'xlsx')
            "
          >
            下载模板
          </span>
        </div>
        <div
          v-for="(tip, index) in props.warningTexts"
          :key="index"
          class="mb-1 text-xs text-gray-500"
        >
          {{ tip }}
        </div>
      </template>

      <!-- 已上传文件，则预览数据 -->
      <div v-if="dataSource.length > 0">
        <a-button
          v-if="unValidateNumber.length > 0"
          type="primary"
          class="float-right ml-2"
          style="margin-top: 2px;"
          @click="downloadError(errorList)"
        >
          下载错误名单
        </a-button>
        <a-button
          type="primary"
          class="float-right"
          style="margin-top: 2px;"
          @click="toggle"
        >
          全屏预览
        </a-button>
        <div class="flex justify-between mt-5 mb-4">
          <div>
            检测结果：共导入{{ dataSource.length }}条，<span
              class="text-green-500"
            >{{ dataSource.length - unValidateNumber.length }}</span>条正常，<span class="text-red-500">{{
              unValidateNumber.length
            }}</span>条异常
          </div>
        </div>
        <a-table
          ref="tableRef"
          :bordered="true"
          :scroll="{
            x: isFullscreen ? 'calc(100vh - 55px)' : tableWidth,
          }"
          :data-source="dataSource"
          :columns="columns"
          :pagination="{
            total: dataSource.length,
            pageSize: size,
            showQuickJumper: true,
            showLessItems: true,
            onShowSizeChange: onShowSizeChange,
            showSizeChanger: true,
          }"
        >
          <template #bodyCell="{ column, record }">
            <span>
              <div
                v-if="errorMsgReg.test(record[column.dataIndex])"
                class="text-red-500"
              >
                {{
                  record[column.dataIndex].substring(
                    record[column.dataIndex].search(errorMsgReg)
                  )
                }}
              </div>
              <div>
                {{ record[column.dataIndex]?.replace(errorMsgReg, '') }}
              </div>
            </span>
          </template>
        </a-table>
      </div>
    </a-form-item-rest>
  </div>
</template>
<script lang="tsx" setup>
import { useMessage } from '@/hooks/message'
import { downloadFile, getExtnameByUrl } from '@/utils/file'
import {
  exportExcelDetail,
  getExcelOriginCell,
  getSheetHeaderAndData,
} from '@/utils/xlsx'
import { TableColumnsType, Divider, UploadFile } from 'ant-design-vue'
import {
  formatToDateTime,
  formatToDate,
  formatToMonth,
  dateFromNow,
} from '@/utils/date'
import {
  DateFormat,
  jobResumeImportProps,
  ValidateKeyAndType,
  FilterDependedKeyAndString,
} from './props'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import { parseDotStrObjToObj, transformObjToDotStrObj } from '@/utils/object'
import { cloneDeep, isEmpty, isUndefined, omit } from 'lodash-es'
import { Table } from 'ant-design-vue/es'
import { getRandomName } from './common/random-text-key'
// import { catchData } from './common/catchData'
import { IExcelInfo } from './excel'
import { unZip } from '@/utils/zip'
import { useEnv } from '@/hooks/env'
import axios from 'axios'
import { toErrorPackage } from './common/toErrorPackage'

type EmitEvents = {
  (e: 'update:value', params: IExcelInfo['resultList']): void
}
const excelInfo = ref<IExcelInfo>({
  resultList: [], // 最后需要传递给form的数据
})

/** 校验携带错误信息的正则 包含 * * * 错误信息 * * * 则说明校验不通过*/
const errorMsgReg = /\*\*\*(.*?)\*\*\*/
const fileList = ref<UploadFile[]>([])
const dataSource = ref<Recordable[]>([])
let errorList = ref<Recordable[]>([])
const columns = ref<TableColumnsType>([])
const unValidateNumber = ref<number[]>([])
const tableRef = ref<InstanceType<typeof Table>>()
const tableWidth = ref(0)
const size = ref(5)
const emits = defineEmits<EmitEvents>()
const props = defineProps(jobResumeImportProps)
const { toggle, isFullscreen } = useFullscreen(tableRef)

const onShowSizeChange = (current: number, pageSize: number) => {
  size.value = pageSize
}
const downloadError = async dataList => {
  let textKeyMaps = Object.keys(props.textKeyMap).map(key => ({
    [key]: props.textKeyMap[key],
  }))
  await exportExcelDetail({
    textKeyMaps,
    dataList,
    excelName: `${props.excelName}信息有误数据表`
  })
}

/** 解决百分比化小数精度问题 （百分比最多三位小数时） */
const formatter = value => {
  let v = (parseFloat(value) / 100).toString()
  let vArr = v.split('.')
  if (vArr.length == 2) {
    v = `${vArr[0]}.${vArr[1].substring(0, 5)}`
    v = `${parseFloat(v)}`
  } else if (vArr.length == 1) {
    return `${v}`
  }
  return v
}

// 重置表格数据
const resetTable = () => {
  fileList.value = []
  dataSource.value = []
  excelInfo.value.resultList = []
  emits('update:value', unref(excelInfo).resultList)
}
// 格式化日期
const formatDate = (
  needFormatDataKey: string[],
  format: DateFormat,
  cloneItem
) => {
  const formatFunctionMap = {
    'YYYY-MM-DD HH:mm': formatToDateTime,
    'YYYY-MM-DD': formatToDate,
    'YYYY-MM': formatToMonth,
    NOW: dateFromNow,
  }
  needFormatDataKey.forEach(key => {
    if (key.split('.').length > 1) {
      // 如果是多级表头
      let dotStrItem = transformObjToDotStrObj(cloneItem)
      dotStrItem[key]
        = dotStrItem[key] && formatFunctionMap[format](dotStrItem[key])
      cloneItem = parseDotStrObjToObj(dotStrItem)
    } else {
      cloneItem[key]
        = cloneItem[key] && formatFunctionMap[format](cloneItem[key])
    }
  })
  return cloneItem
}
// 去百分号
const removePercentSign = (
  cloneDataList,
  needRemovePercentKey: string[],
  cloneItem,
  cloneItemIndex
) => {
  needRemovePercentKey.forEach(key => {
    if (key.split('.').length > 1) {
      // 如果是多级表头
      let dotStrItem = transformObjToDotStrObj(cloneItem)
      dotStrItem[key] = dotStrItem[key] && formatter(dotStrItem[key]) // 百分比化小数
      cloneDataList[cloneItemIndex] = parseDotStrObjToObj(dotStrItem)
    } else {
      cloneItem[key] = cloneItem[key] && formatter(cloneItem[key])
    }
  })
  return cloneDataList
}
// 校验数据
const validateData = (
  validateKeyAndType: ValidateKeyAndType,
  tempUnValidateNumber,
  cloneItem,
  row
) => {
  const realNameReg = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/
  const bankAccountReg = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/

  /* 错误信息映射 */
  const errorMassageMap = {
    not: '', // 必填项
    jobUserName: '姓名不能为数字或少于2个中文字',
    jobUserMobile: '联系手机格式不对',
  }
  // 校验函数映射
  const validateFunctionMap = {
    not: item => item,
    jobUserName: jobUserName => realNameReg.test(jobUserName),
    jobUserMobile: item => isMobilePhone(item || '', 'zh-CN'),
  }
  Object.keys(validateKeyAndType).forEach((key, column) => {
    if (key.split('.').length > 1) {
      console.log('多级')
      // 如果是多级表头
      cloneItem = transformObjToDotStrObj(cloneItem)
    }
    if (validateKeyAndType[key].required) {
      if (isUndefined(cloneItem[key])) {
        cloneItem[key] = toErrorPackage('必填')
        tempUnValidateNumber.push(row)
        return
      }
      // 关联校验 如果某项必填，另一项也需要必填的关联
      if (
        cloneItem[key] === validateKeyAndType[key].associationValidateString
        && isUndefined(cloneItem[validateKeyAndType[key].requiredAssociationKey as string])
      ) {
        cloneItem[validateKeyAndType[key].requiredAssociationKey as string]
          = toErrorPackage('必填')
        tempUnValidateNumber.push(row)
        return
      }
    }
    if (!validateFunctionMap[validateKeyAndType[key].type](cloneItem[key])) {
      if (isUndefined(cloneItem[key])) {
        return
      }
      // 加上错误信息在具体位置
      cloneItem[key] = cloneItem[key]
        ? `${cloneItem[key]}  ${toErrorPackage(errorMassageMap[validateKeyAndType[key].type])}`
        : `${toErrorPackage(errorMassageMap[validateKeyAndType[key].type])}`
      tempUnValidateNumber.push(row)
    }
  })
  return {
    cloneItem,
  }
}
// 过滤数据
const dataFilter = (
  cloneDataList,
  filterDependedKeyAndString: FilterDependedKeyAndString
) =>
  cloneDataList.filter(item => {
    if (filterDependedKeyAndString.key.split('.').length > 1) {
      // 如果是多级
      item = transformObjToDotStrObj(item)
    }
    return (
      item[filterDependedKeyAndString.key]
      === filterDependedKeyAndString.dependedString
    )
  })
async function upload(file) {
  let formData = new FormData()
  formData.append('file', file)
  let res
  res = await axios.post(useEnv.uploadApiUrl, formData)
  return res
}

// 压缩包文件错误信息
const errorFileMsgList: any = ref([])
// 已上传提示信息
const uploadSuccessMsgList: any = ref([])
// 单个sheet导入 目前默认是表头第一个
const singleSheet = sheetList => {
  console.log(sheetList, 'sheetList')
  // 去掉表头第一行 是注解项
  sheetList[0].splice(0, 1)
  let { headerColumns, dataList, dataSourceList } = getSheetHeaderAndData(
    sheetList[0],
    props.textKeyMap
  )
  console.log(headerColumns, dataList, dataSourceList, 'sdfsdf')
  const headerColumnsTextKey = headerColumns.map(i => i.title)
  const textKey = Object.keys(props.textKeyMap)

  /* 处理除配置以外的表头数据 生成随机的字段用于展示*/
  const excessTextKey = getRandomName(headerColumnsTextKey.length - textKey.length)
  console.log(excessTextKey, '随机key')
  excessTextKey.forEach((key, index) => {
    headerColumns[textKey.length + index].dataIndex = key
  })
  dataList = dataList
    .filter(item => !isEmpty(item))
    .map(i => {
      let excessKeyAndValue: { key: string; value: string; label: string }[]
        = []
      let excessKeyAndValue1 = {}
      let needOmitKey: string[] = []
      excessTextKey.forEach((key, index) => {
        needOmitKey.push(headerColumns[textKey.length + index].title!)
        excessKeyAndValue1[headerColumns[textKey.length + index].title!]
          = i[headerColumns[textKey.length + index].title!]
        excessKeyAndValue.push({
          label: headerColumns[textKey.length + index].title!,
          value: i[headerColumns[textKey.length + index].title!],
          key: headerColumns[textKey.length + index].dataIndex!,
        })
        i[headerColumns[textKey.length + index].dataIndex!]
          = i[headerColumns[textKey.length + index].title!]
      })
      if (!isEmpty(excessKeyAndValue1)) {
        i.feildList = excessKeyAndValue1
      }
      return omit(i, ...needOmitKey)
    })
  let status = false
  // 单sheet导入通过判断配置的表头名字来验证是否选错导入表格(为了可以自定义表头导入，只要选择的表头包含了配置的表头既正确)
  const inequalityArr = textKey.filter(key => !headerColumnsTextKey.includes(key))
  if (
    inequalityArr.length === 0
    && textKey.length === headerColumnsTextKey.length
  ) {
    status = true
  }
  let errorAndOriginalDataList: Recordable[] = []
  let tempUnValidateNumber: number[] = []
  let cloneDataList = cloneDeep(dataList)
  cloneDataList.forEach((item, index) => {
    // 是否去掉百分号
    if (props.needRemovePercentKey.length) {
      dataList[index] = removePercentSign(
        cloneDataList,
        props.needRemovePercentKey,
        item,
        index
      )
    }
    // 先格式化再验证，不会显示invalid date
    // 是否格式化日期
    if (props.needFormatDataKey.length) {
      dataList[index] = formatDate(
        props.needFormatDataKey,
        props.format,
        item
      )
    }
    // 是否校验数据
    if (!isEmpty(props.validateKeyAndType)) {
      let { cloneItem } = validateData(
        props.validateKeyAndType,
        tempUnValidateNumber,
        item,
        index
      )
      errorAndOriginalDataList.push(cloneItem)
    }
  })
  unValidateNumber.value = Array.from(new Set(tempUnValidateNumber)) // 去除重复索引
  // 过滤出没通过校验的数据
  let unValiDateDataList = errorAndOriginalDataList.filter((item, index) =>
    unValidateNumber.value.includes(index))
  //  过滤出通过校验的数据
  let valiDateDataList = cloneDataList.filter((item, index) => !unValidateNumber.value.includes(index))
  console.log(
    valiDateDataList,
    '通过校验的数据',
    unValiDateDataList,
    '错误的数据'
  )

  // 是否过滤数据
  if (props.isFilter) {
    valiDateDataList = dataFilter(
      valiDateDataList,
      props.filterDependedKeyAndString!
    )
  }
  return {
    headerColumns,
    errorAndOriginalDataList,
    valiDateDataList,
    unValiDateDataList,
    status
  }
}
// 多个sheet导入
const multipleSheets = (sheetList: string[][][], sheetNames: string[]) => {
  let excelList = [] as IExcelInfo['resultList']
  let status = true
  // 多sheet导入通过判断sheet的名字来验证是否选错导入表格
  if (sheetNames.join(',') !== props.sheetNames.join(',')) {
    status = false
    resetTable()
    return {
      excelList,
      status,
    }
  }
  for (let i = 0; i < sheetList.length; i++) {
    if (props.notResolvedSheetIndex.includes(i)) {
      // 跳过不需要解析的sheet
      continue
    }
    let { headerColumns, dataList, dataSourceList } = getSheetHeaderAndData(
      sheetList[i],
      props.textKeyMapArray[i].textKeyMap
    )
    // 是否去掉百分号
    if (props.textKeyMapArray[i].needRemovePercentKey) {
      let cloneDataList = cloneDeep(dataList)
      cloneDataList.forEach((item, index) => {
        item = removePercentSign(
          cloneDataList,
          props.textKeyMapArray[i].needRemovePercentKey!,
          item,
          index
        )
      })
      dataList = cloneDataList
    }
    // 是否过滤数据
    if (props.textKeyMapArray[i].isFilter) {
      dataList = dataFilter(
        dataList,
        props.textKeyMapArray[i].filterDependedKeyAndString!
      )
    }
    excelList.push({
      headerColumns,
      dataList,
      dataSourceList,
    })
  }
  return {
    excelList,
    status,
  }
}
function handleChange({ file }) {
  uploadSuccessMsgList.value = []
  errorFileMsgList.value = []
  let uploadResult: any = []
  let excelFile
  // 如果重写选择上传，把值给清空
  // emits('update:value', uploadResult)
  unZip(file).then(async res => {
    (res as any).forEach((item: any) => {
      let filesize = item.file.size / 1024 / 1024
      if (getExtnameByUrl(item.name) === 'xlsx') {
        excelFile = item.file
      }
      if (filesize > props.maxSize) {
        errorFileMsgList.value = [
          ...errorFileMsgList.value,
          {
            filename: item.name,
            filasize: filesize
          },
        ]
      }
    })
    // 如果有文件过大的，不让上传
    if (errorFileMsgList.value.length > 0) {
      return useMessage.error(`${errorFileMsgList.value.map(item => item.name).join(',')}大小超过${props.maxSize}M，请检查`)
    }
    if (!excelFile) {
      return useMessage.error('表格文件不存在，请检查')
    }


    let resultList = await Promise.all((res as any).map(item => upload(item.file)))
    uploadResult = resultList.map(item => item.data.data)
    uploadSuccessMsgList.value = resultList.map(item => ({ filename: item.data.data.name }))

    unValidateNumber.value = []
    if (file.status === 'removed') {
      resetTable()
    }
    if (excelFile) {
      const { excelData: sheetList, sheetNames } = await getExcelOriginCell(excelFile)
      console.log(sheetList)
      console.log(sheetNames)
      if (
        props.maxImportNum + 1
        < (props.textKeyMapArray.length > 0
          ? sheetList.reduce((sum, sheet) => sum + sheet.length, 0)
          : sheetList[0].length)
      ) {
        useMessage.error(`最多导入${props.maxImportNum}条`)
        resetTable()
        return
      }

      if (props.textKeyMapArray.length > 0) {
        const { excelList, status } = multipleSheets(sheetList, sheetNames)
        if (!status) {
          useMessage.error('模板错误，请选择正确模板')
          return
        }
        excelInfo.value.resultList = excelList
      } else {
        const {
          headerColumns,
          unValiDateDataList,
          errorAndOriginalDataList,
          valiDateDataList,
          status,
        } = singleSheet(sheetList)
        if (!status) {
          useMessage.error('模板错误，请选择正确模板')
          return
        }
        console.log('headerColumns', headerColumns) // table 表头结构
        console.log('errorAndOriginalDataList', errorAndOriginalDataList) // table 表格数据
        console.log('valiDateDataList', valiDateDataList) // 传递给后端的数据
        console.log('unValiDateDataList', unValiDateDataList)
        tableWidth.value = headerColumns.length * 110
        columns.value = headerColumns
        dataSource.value = errorAndOriginalDataList
        errorList.value = unValiDateDataList

        // if (props.transFormData) {
        //   let [succesDataList, errorDataList] = props.transFormData(valiDateDataList, uploadResult)
        //   excelInfo.value.resultList = succesDataList
        //   errorDataList.forEach(item => {
        //     if (unValiDateDataList.includes(unvalidate => unvalidate.id === item.id)) {
        //       unValiDateDataList.push(cloneDeep(item))
        //     }
        //   })
        //   console.log(unValiDateDataList, 'unValiDateDataList eror')

        // } else {
        //   excelInfo.value.resultList = valiDateDataList
        // }

        // props?.transFormData 转化数据格式，匹配简历和excel表格中的人
        excelInfo.value.resultList = props?.transFormData ? props?.transFormData(valiDateDataList, uploadResult) : valiDateDataList
        // console.log(excelInfo.value.resultList, 'excelInfo.value.resultList')
        // console.log(uploadResult, 'rrrr')
        // todo 特殊处理，要表格数据和pdf 文件关联
        emits('update:value', excelInfo.value.resultList)
      }
    }

  })
  // catchData(excelInfo.value.resultList, () => {
  //   emits('update:value', unref(excelInfo).resultList)
  //   fileList.value = [file]
  // })
}
// onMounted(() => {

//   /* 挂载好提交一次默认值，防止直接提交控制台报错（在form中会失去必填校验效果） */
//   emits('update:value', unref(excelInfo).resultList)
// })

// 一级菜单映射
// const textKeyMap = {
//   姓名: 'data.realname',
//   证件类型: 'data.mobile', // todo
//   证件号码: 'data.idcard',
//   手机号码: 'data.mobile',
//   性别: 'data.sex',
//   部门: 'data.departmentName',
//   岗位: 'data.jobName',
//   签约公司: 'data.staffContractName',
//   入职日期: 'data.entryDate',
//   合同开始日: 'data.staffStartedAt',
//   合同结束日: 'data.staffEndedAt',
//   试用期开始日: 'data.probationStartAt',
//   试用期结束日: 'data.probationEndAt',
//   开户银行: 'data.bankName',
//   银行支行: 'data.bankBranchInfo',
//   银行账号: 'data.bankCard',
//   开户行省市: 'data.bankCity',
//   户籍地址: 'data.houseAdress',
//   现住址: 'data.currentLiveAddress',
//   紧急联系人: 'data.urgentContacts',
//   紧急联系人电话: 'data.urgentContactsPhone',
//   是否购买社保: 'data.isBuySocial',
//   是否购买公积金: 'data.isBuyProvident',
//   购买社保时间: 'data.socialAddMonth',
//   购买公积金时间: 'data.providentAddMonth',
//   工作地: 'data.workCityName',
//   学历: 'data.education',
//   电子签: 'data.isElectronicSignature',
//   备注: 'data.remark',
//   婚育情况: 'data.marry',
//   用工类型: 'data.staffType',
// }

// 二级菜单映射
// const textKeyMap = {
//   '*姓名': 'user.realname',
//   '*手机号': 'user.mobile',
//   '*身份证号': 'user.idcard',
//   '*员工状态': 'employee.status',
//   '*员工类型': 'employee.type',
//   '*学历': 'employee.education',
//   '*应聘职位': 'employee.jobName',
//   '*性别': 'employee.sex',
//   '*户籍地址': 'employee.nativeAddress',
//   '*期望薪资（元/月）': 'employee.jobSalary',
//   '*现居住地址': 'employee.presentAddress',
//   '*客户单位': 'employee.companyName',
//   '*身高（cm）': 'employee.height',
//   '紧急联系人.*联系人姓名': 'employee.emergencyName',
//   '紧急联系人.*关系': 'employee.emergencyType',
//   '紧急联系人.*联系人电话': 'employee.emergencyMobile',
//   '紧急联系人.通讯地址': 'employee.emergencyAddress',

//   '主要工作经历1.*公司名称': 'workExperience1.companyName',
//   '主要工作经历1.*开始日期': 'workExperience1.startedAt',
//   '主要工作经历1.*结束日期': 'workExperience1.endedAt',
//   '主要工作经历1.*部门': 'workExperience1.departmentName',
//   '主要工作经历1.*职位': 'workExperience1.jobName',
//   '主要工作经历1.证明人': 'workExperience1.referenceName',
//   '主要工作经历1.电话': 'workExperience1.referenceMobile',

//   '主要工作经历2.公司名称': 'workExperience2.companyName',
//   '主要工作经历2.开始日期': 'workExperience2.startedAt',
//   '主要工作经历2.结束日期': 'workExperience2.endedAt',
//   '主要工作经历2.部门': 'workExperience2.departmentName',
//   '主要工作经历2.职位': 'workExperience2.jobName',
//   '主要工作经历2.证明人': 'workExperience2.referenceName',
//   '主要工作经历2.电话': 'workExperience2.referenceMobile',

//   '主要工作经历3.公司名称': 'workExperience3.companyName',
//   '主要工作经历3.开始日期': 'workExperience3.startedAt',
//   '主要工作经历3.结束日期': 'workExperience3.endedAt',
//   '主要工作经历3.部门': 'workExperience3.departmentName',
//   '主要工作经历3.职位': 'workExperience3.jobName',
//   '主要工作经历3.证明人': 'workExperience3.referenceName',
//   '主要工作经历3.电话': 'workExperience3.referenceMobile',
// }

// 三级菜单中文与数据结构的映射
// const textKeyMap = {
//   所得项目: 'project',
//   '本月（次）情况.收入额计算.收入': 'month.income.income',
//   '本月（次）情况.收入额计算.费用': 'month.income.fee',
//   '本月（次）情况.收入额计算.免税收入': 'month.income.freeIncome',
//   '本月（次）情况.减除费用': 'month.cut',
//   '本月（次）情况.专项扣除.基本养老保险费': 'month.specialCut.forOld',
//   '本月（次）情况.专项扣除.基本医疗保险费': 'month.specialCut.forMedical',
//   减按计税比例: 'rate',
// }
</script>
