<template>
  <a-select
    v-bind="bindAttrs"
    @dropdownVisibleChange="handleFetch"
    @popupScroll="handleScroll"
  >
    <template
      v-if="loading"
      #suffixIcon
    >
      <loading-outlined spin />
    </template>
    <template #notFoundContent>
      <div
        v-if="isSearched"
        class="text-center"
      >
        暂无数据
      </div>
    </template>
  </a-select>
</template>
<script lang="ts" setup>
import { computed, unref, useAttrs } from 'vue'
import { get, isFunction, isArray, debounce } from 'lodash-es'
import { getCacheApi } from './api-cache'
import { customApiSelectProps } from './props'
function uniqueFunc(arr: any[], uniId: string) {
  const res = new Map()
  return arr.filter(item => !res.has(item[uniId]) && res.set(item[uniId], item))
}
const props = defineProps(customApiSelectProps)
type EmitEvents = {
  (e:'change', ...value):void
  (e:'update:value', ...value):void
  (e: 'options-change', options: Recordable[]): void
  (e: 'option-select', ...value):void
}
const emits = defineEmits<EmitEvents>()
const attrs = useAttrs()

const loading = ref(false)
const isFetched = ref(false)
const options = ref<Recordable[]>([])
const value = computed(() => (props.value === 0 && props.zeroToUndefined ? undefined : props.value))
const isSearched = ref(false)
let page = 1
let lockPage = false
const handleSearch = debounce(async () => {
  if (props.showSearch) {
    resetPage()
    let res:any = await fetch()
    if (res) {
      isSearched.value = true
    }
  }
}, 500)
function resetPage() {
  page = 1
  lockPage = false
}
async function handleScroll(e) {
  const { target } = e
  const scrollTop = target.scrollTop
  const scrollHeight = target.scrollHeight - scrollTop
  const clientHeight = target.clientHeight
  if (!lockPage && !unref(loading) && scrollHeight < clientHeight + 2) {
    page++
    await fetch({}, result => {
      options.value = options.value.concat(result)
      // 通过value去重
      options.value = uniqueFunc(options.value, 'value')
      target.scrollTop = scrollTop
    })

  }
}

onMounted(() => {
  props.immediate && fetch()
})

watch(() => props.apiParams, (value, oldValue) => {
  if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
    fetch()
  }
})

async function handleFetch(res) {
  if (res) {
    resetPage()
    await fetch()
  }
}
async function fetch(params?:any, callback?: Fn) {
  const { api, apiParams, resultField, labelField, valueField, numberToString, pageSize } = props
  if (!api || !isFunction(api)) {
    return
  }
  loading.value = true
  const res = await getCacheApi(api, {
    isPage: props?.isPage,
    ...apiParams,
    ...params,
    page,
    pageSize
  })
  let optionData = isArray(res) ? res : get(res, resultField)
  let newOptions = optionData?.map((item:any, index:number) => {
    const optionValue = item[valueField]
    return {
      label: isFunction(labelField) ? labelField(item) : item[labelField as string],
      value: numberToString ? `${optionValue}` : optionValue,
      key: `${optionValue}${index}`,
      origin: item
    }
  })
  // 全部返回的数据没有pagination, 则认为是最后一页
  if (res.pagination === undefined || newOptions.length < pageSize) {
    lockPage = true
  }
  if (callback) {
    callback(newOptions)
  } else {
    options.value = newOptions
  }

  // isFetched.value = true
  if (v.value) {
    optionSelect(v.value)
  }
  emits('options-change', options.value)
  loading.value = false
}
const getValue = (value: any) => {
  let tmp = unref(value)
  if (!loading.value) {
    return tmp
  }
  if ((isArray(tmp) && tmp?.length !== 0) || (!isArray(tmp) && tmp)) {
    return '加载中'
  } else {
    return unref(value)
  }
}
const v = ref()
watch(
  () => props.value,
  value => {
    if (value === 0 && props.zeroToUndefined) {
      v.value = undefined
    } else {
      const valueUnref = toRaw(unref(value))
      if (valueUnref && !options.value.some(item => (isArray(valueUnref) ? valueUnref.includes(item.value) : item.value === valueUnref))) {

        fetch({ [props.PriKeyField]: valueUnref })
      }
      v.value = value
    }
  },
  {
    immediate: true,
  },
)
watch(
  () => v.value,
  v => {
    emits('update:value', v)
    optionSelect(v)
  },
)
function optionSelect(v) {
  if (Array.isArray(v)) {
    emits(
      'option-select',
      options.value.filter(o => v.indexOf(o.value) > -1),
    )
  } else {
    emits(
      'option-select',
      options.value.find(o => o.value === v),
    )
  }
}
const bindAttrs = computed(() => ({
  dropdownStyle: { maxHeight: '280px' },
  optionFilterProp: 'label',
  value: getValue(value),
  options: unref(options),
  onChange: (...newValue) => emits('change', ...newValue),
  'onUpdate:value': (...newValue) => emits('update:value', ...newValue),
  ...attrs,
  ...(props.showSearch ? {
    showSearch: true,
    // onSearch: handleSearch
  } : {}),
}))
</script>
