<template>
  <a-input-group class="!flex">
    <CompanyApiAelect
      v-bind="$attrs"
      :key="datekey"
      class="flex-1"
      :api-params="apiParams"
      style="width: 90%;"
    />
    <a-button
      type="primary"
      @click="open"
    >
      <template #icon>
        <plus-outlined />
      </template>
    </a-button>
    <basic-modal
      v-bind="bindSocialModelAttr"
      ref="basicFormRef"
    >
      <basic-form
        v-bind="socialPlansAttr"
        ref="basicFormRef"
      />
    </basic-modal>
  </a-input-group>
</template>
<script lang="ts" setup>
// 客户单位名称
import { companyApiSelectProps } from './props'
import CompanyApiAelect from './company-api-select.vue'
import { useUserStore } from '@/store/modules/user'
import { customerManage } from '@/api/customer'
import { BasicModalProps } from '@/components/modal/prop'
import { useMessage } from '@/hooks/message'
const props = defineProps(companyApiSelectProps)
const { apiParams } = props
const visible = ref(false)

const basicFormRef = ref()
const datekey = ref()

// 模态框参数
const bindSocialModelAttr = computed((): BasicModalProps => ({
  title: '新增客户单位',
  width: 666,
  visible: unref(visible),
  okText: '提交',
  cancelText: '取消',
  okButtonProps: {
    type: 'primary'
  },
  destroyOnClose: true,
  onOk: async () => {

    await basicFormRef.value.validate()

    await customerManage.addCustomer({
      ...basicFormRef.value.formModel
    })
    datekey.value = Date.now()
    visible.value = false
  },
  onCancel: () => {
    visible.value = false
  },
}))
// 模态框社保表格参数
const socialPlansAttr = computed((): IForm.Props => ({
  actionable: false,
  labelAlign: 'left',
  layout: 'vertical',
  schemas: [
     {
       span: 24,
       component: 'Input',
       field: 'name',
       label: '客户单位名称',
       componentProps: {
         placeholder: '请输入客户单位名称(即客户名称)'
       },
       required: true
     } as IForm.Schema<'Input'>,
    {
      field: 'desc',
      span: 24,
      label: '简介',
      component: 'InputTextArea',
      componentProps: {
        showCount: true,
        maxlength: 300,
        autoSize: {
          minRows: 7,
          maxRows: 7
        }

      },
    } as IForm.Schema<'InputTextArea'>
  ],
}))


function open() {
  if (!useUserStore().hasPermission('CUSTOMER_CREATE')) {
    return useMessage.error('没有添加客户单位权限')
  }
  visible.value = true
}
</script>
