<template>
  <div
    class="flex items-center"
  >
    <a-form-item-rest>
      <a-input-number
        v-model:value="value1"
        :min="min"
        :addon-before="addonBefore"
        :addon-after="addonAfter"
        :placeholder="`${placeholder?.replace(/请输入(.*)/,'最小$1')}`"
        @blur="handleBlur1"
        @update:value="handleChange"
      />
      <div class="mx-[8px]">
        {{ middleText }}
      </div>
      <a-input-number
        v-model:value="value2"
        :max="max"
        :addon-before="addonBefore"
        :addon-after="addonAfter"
        :placeholder="`${placeholder?.replace(/请输入(.*)/,'最大$1')}`"
        @blur="handleBlur2"
        @update:value="handleChange2"
      />
    </a-form-item-rest>
  </div>
</template>

<script lang="ts" setup name="input-money">
import { inputRangeProps } from './props'
import { isArray, isUndefined } from 'lodash-es'
const props = defineProps(inputRangeProps)
let twoInputEntry = false

type EmitEvents = {
  (e:'update:value', value):void
}

const emits = defineEmits<EmitEvents>()
const value1 = ref<number | string>('')
const value2 = ref<number | string>('')
watch(() => props.value, v => {
  if (checkValues(v)) {
    value1.value = v[0]
    value2.value = v[1]
    twoInputEntry = true
  } else if (isUndefined(v)) {
    value1.value = ''
    value2.value = ''
    twoInputEntry = false
  }
}, { immediate: true })


function handleBlur1() {
  if ((unref(value1) || unref(value1) === 0) && (unref(value2) || unref(value2) === 0)) {
    emits('update:value', [unref(value1), unref(value2)])
  } else if ((!unref(value1) || !unref(value2)) && twoInputEntry) {
    emits('update:value', [])
  }
}
function handleBlur2() {
  if ((unref(value1) || unref(value1) === 0) && (unref(value2) || unref(value2) === 0)) {
    emits('update:value', [unref(value1), unref(value2)])
  } else if (!unref(value1) || !unref(value2)) {
    emits('update:value', [])
  }
  twoInputEntry = true
}
function handleChange(value) {
  emits('update:value', [value, unref(value2)])
}
function handleChange2(value) {
  emits('update:value', [unref(value1), value])
}

function checkValues(v) {
  return isArray(v) && v.length === 2 && v[0] && v[1]
}
</script>
