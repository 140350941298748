import { redirectRouter } from '@/hooks/router-redirect'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
const systemChildrenRoutes = [
  {
    path: 'user-manage',
    name: 'userManage',
    component: EmptyLayout,
    meta: {
      title: '用户管理',
      iconfont: 'icon-people_outline_black_24dp',
      requiredPermission: 'USER_MANAGE',
    },
    children: [
      {
        path: 'admin-list',
        name: 'adminList',
        component: () => import('@/views/system/user-manage/admin-list.vue'),
        meta: {
          title: '用户与部门管理',
          iconfont: 'icon-people_outline_black_24dp',
          requiredPermission: 'ADMIN_LIST',
        },
      },
      {
        path: 'role-list',
        name: 'roleList',
        component: () => import('@/views/system/user-manage/role-list.vue'),
        meta: {
          title: '角色管理',
          iconfont: 'icon-sentiment_very_satisfied_black_24dp',
          requiredPermission: 'ROLE_LIST',
        },
      },
    ]
  },
  {
    path: 'notification-manage',
    name: 'notificationMANAGE',
    component: EmptyLayout,
    meta: {
      title: '消息管理',
      iconfont: 'icon-notifications_black_24dp',
      requiredPermission: 'NOTIFICATION_MANAGE',
      showBadge: 'unreadMessageCount'
    },
    children: [
      {
        path: 'notification-list',
        name: 'notificationList',
        component: () => import('@/views/system/notification-manage/notification-list.vue'),
        meta: {
          title: '消息列表',
          iconfont: 'icon-notifications_black_24dp',
          requiredPermission: 'NOTIFICATION_LIST',
          showBadge: 'unreadMessageCount'
        },
      },
      {
        path: '/notification-setting',
        name: 'notificationSetting',
        component: () => import('@/views/system/notification-manage/notification-setting.vue'),
        meta: {
          title: '消息设置',
          iconfont: 'icon-chat-settings-line',
          requiredPermission: 'NOTIFICATION_SETTING',
        },
      },
    ]
  },
  {
    path: 'custom-field-manage',
    name: 'customFieldManage',
    component: EmptyLayout,
    meta: {
      title: '字段管理',
      iconfont: 'icon-settings-fill',
      requiredPermission: 'CUSTOM_FIELD_MANAGE',
    },
    children: [
      {
        path: 'custom-field-list',
        name: 'customFieldList',
        component: () => import('@/views/system/custom-field-manage/custom-field-list.vue'),
        meta: {
          title: '自定义字段设置',
          iconfont: 'icon-settings-fill',
          requiredPermission: 'CUSTOM_FIELD_LIST',
        }
      },
    ]
  },
  {
    path: 'account-safety-manage',
    name: 'accountSafetyManage',
    component: EmptyLayout,
    meta: {
      title: '账户安全',
      iconfont: 'icon-briefcase-line',
      requiredPermission: 'ACCOUNT_SAFETY_MANAGE',
    },
    children: [
      {
        path: 'account-safety',
        name: 'accountSafety',
        component: () => import('@/views/system/account-safety-manage/account-safety.vue'),
        meta: {
          title: '账户安全',
          iconfont: 'icon-briefcase-line',
          requiredPermission: 'ACCOUNT_SAFETY',
        },
      },
    ]
  },


]
export const systemRoutes: CustomRouteRecordRaw = {
  path: 'system',
  name: 'system',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(systemChildrenRoutes),
  meta: {
    title: '系统设置',
    iconfont: 'icon-icon_chilun',
    desc: '以系统为维度内容',
    requiredPermission: 'SYSTEM',
    noQuick: true
  },
  children: systemChildrenRoutes
}
