<style scoped>

</style>

<template>
  <basic-form v-bind="bindFormProps" />
</template>

<script setup>
const props = defineProps({
  value: Array
})
const bindFormProps = computed(() => ({
  layout: 'vertical',
  showAction: false,
  showFooter: false,
  model: {
    employer_post: props.value,
  },
  schemas: [
    {
      field: 'employer_post',
      span: 24,
      component: 'DynamicForm',
      componentProps: {
        isTableMode: false,
        needAddDel: false,
        needPlus: false,
        dynamicValidateForm: [
          {
            field: 'title',
            label: '岗位名称',
            componentProps: {
              disabled: true
            },
            required: true,
            span: 11,
          },
          {
            label: '工作内容描述',
            field: 'job_description',
            span: 11,
            required: true,
          }
        ]
      }
    }
  ]
}))
</script>