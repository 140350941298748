import { request } from '@/utils/request'

// 项目列表
const projectApi = {
  //  【】获取项目列表
  getProjectList: params => request.get('projects/projects/index', params),
  // 【】 获取项目详情
  getProjectDetail: id => request.get(`projects/Projects/show/${id}`),

  // 【】编辑项目
  updateProject: (id, params) =>
    request.post(`projects/Projects/update/${id}`, params),

  // 【】 新增项目
  addProject: params => request.post('projects/Projects/store', params),

  //  【】 获取项目名称以及id列表
  getProjectSelector: (params = {}) =>
    request.get('projects/Projects/getProjectNameList', params),

  /* 获取二维码链接 */
  getQrcode: (id, params) => request.get(`projects/${id}/qrcode`, params),
  downLoadQr: (params, id) => request.post(`projects/${id}/downqrcode`, params),

  //  【】获取项目列表
  getProjectGroupList: () => request.get('projects/projects/project2Group'),

  //  【】获取在职员工证件即将过期列表
  getIdcardToBeExpiredList: params =>
    request.post('Staff/StaffUsersIdcard/lists', params),

  //  【】获取各模块待处理事项数量
  getIdcardToBeExpiredNum: () =>
    request.get(
      'Staff/StaffUsersIdcard/getPendingMessageTotal',
      {},
      { timeout: 60 * 1000 * 1000 }
    ),

  // 【】获取导出文件列表
  getIdcardExportList: params =>
    request.post('Export/ZipTasks/lists', params),

  // 【】身份证即将过期列表-导出数据
  exportIdcard: params =>
    request.post('staff/staffUsersIdcard/index', params),
}

// 配置员工信息字段
const staffFieldApi = {
  // 配置员工信息字段列表
  getStaffFieldList: params =>
    request.post('staff/staff_user_export_field/lists', params),

  // 配置员工信息字段
  updateStaffField: params =>
    request.post('staff/staff_user_export_field/store', params),


  // 获取所有配置的字段
  getConfigAllFieldList: id => request.post(`staff/staff_group_field/getAllField/${id}`),
  // 保存勾选的字段
  saveConfigField: (id, params) => request.post(`/staff/staff_group_field/saveProjectField/${id}`, params),
  // 获取项目配置的字段
  getProjectConfigFieldData: (id, params) => request.post(`/staff/staff_group_field/getAllCostomField/${id}`, params),
}

export { projectApi, staffFieldApi }
