import { redirectRouter } from '@/hooks/router-redirect'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
const commercialInsuranceChildrenRoutes = [
  // 商保菜单
  {
    path: 'project',
    name: 'commercialInsuranceProjectList',
    component: () => import('@/views/commercialInsurance/project/index.vue'),
    meta: {
      title: '项目列表',
      iconfont: 'icon-pages-line',
      requiredPermission: 'COMMERCIAL_INSURANCE_PROJECT_LIST'
    },
  },
  {
    path: 'apply',
    name: 'commercialInsuranceApply',
    component: () => import('@/views/commercialInsurance/apply/apply.vue'),
    meta: {
      title: '商保申请',
      iconfont: 'icon-calendar-check-line',
      requiredPermission: 'COMMERCIAL_INSURANCE_APPLY',
    },
  },

  {
    path: 'project/detail/:id',
    name: 'commercialInsuranceProjectDetail',
    component: () => import('@/views/commercialInsurance/project/detail.vue'),
    meta: {
      title: '项目明细',
      hideInMenu: true,
      parentNameForBreadcrumb: 'CommercialInsuranceProjectList',
      requiredPermission: 'COMMERCIAL_INSURANCE_PROJECT_LIST_DETAIL'
    },
  },
  {
    path: 'employer-post-apply',
    name: 'employerPostApply',
    component: () => import('@/views/commercialInsurance/employer-post-apply/employer-post-apply.vue'),
    meta: {
      title: '核岗申请',
      iconfont: 'icon-pages-line',
      requiredPermission: 'EMPLOYER_POST_APPLY',
    },
  },
  {
    path: 'policy-list',
    name: 'commercialInsurancePolicy',
    component: () => import('@/views/commercialInsurance/policy/policy.vue'),
    meta: {
      title: '保单数据列表',
      iconfont: 'icon-contacts-book-line',
      requiredPermission: 'COMMERCIAL_INSURANCE_POLICY',
    },
  },
  {
    path: 'personnel-records',
    name: 'commercialInsurancePersonnelRecords',
    component: () => import('@/views/commercialInsurance/personnel-records/index.vue'),
    meta: {
      title: '参保人员数据记录',
      iconfont: 'icon-survey-line',
      requiredPermission: 'COMMERCIAL_INSURANCE_PERSONNEL_RECORDS',
    },
  },
  {
    path: 'employer',
    name: 'commercialInsuranceEmployer',
    component: () => import('@/views/commercialInsurance/employer/employer.vue'),
    meta: {
      title: '用工企业',
      iconfont: 'icon-survey-line',
      requiredPermission: 'COMMERCIAL_INSURANCE_EMPLOYER',
    },
  },
  {
    path: 'employer-post',
    name: 'commercialInsuranceEmployerPost',
    component: () => import('@/views/commercialInsurance/employer/employer-post.vue'),
    meta: {
      title: '企业核岗记录',
      hideInMenu: true,
      iconfont: 'icon-survey-line',
      parentNameForBreadcrumb: 'commercialInsurance',
      requiredPermission: 'COMMERCIAL_INSURANCE_EMPLOYER_POST',
    },
  },
  {
    path: 'plan',
    name: 'commercialInsurancePlan',
    component: () => import('@/views/commercialInsurance/plan/plan.vue'),
    meta: {
      title: '商保方案',
      iconfont: 'icon-survey-line',
      requiredPermission: 'COMMERCIAL_INSURANCE_PLAN',
    },
  },
  {
    path: 'company',
    name: 'commercialInsuranceCompany',
    component: () => import('@/views/commercialInsurance/company/company.vue'),
    meta: {
      title: '被保险人公司',
      iconfont: 'icon-survey-line',
      requiredPermission: 'COMMERCIAL_INSURANCE_COMPANY',
    },
  },
]
export const commercialInsuranceRoutes: CustomRouteRecordRaw = {
  path: 'commercialInsurance',
  name: 'commercialInsurance',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(commercialInsuranceChildrenRoutes),
  meta: {
    title: '商保',
    iconfont: 'icon-emotion-laugh-line',
    desc: '以商保为维度内容',
    requiredPermission: 'COMMERCIAL_INSURANCE',
    needIcon: true,
    noQuick: true
  },
  children: commercialInsuranceChildrenRoutes
}

