import { defineStore } from 'pinia'

interface SystemState {
  isPc: boolean;
  miniScreen: boolean;
  selectedTopMenu: string;
  homePath: string;
}
export const useSystemStore = defineStore('system', {
  state: (): SystemState => ({
    isPc: true,
    miniScreen: false,
    selectedTopMenu: '',
    homePath: '/workbench',
  }),
  getters: {},
  actions: {
    toggleDevice(isPc: boolean) {
      this.isPc = isPc
    },
    setMiniScrren(v: boolean) {
      this.miniScreen = v
    }
  }
})