<template>
  <a-input-group class="!flex">
    <CompanyApiAelect
      :key="datekey"
      class="flex-1"
      :api-params="apiParams"
      v-bind="$attrs"
      style="width: 90%;"
    />
    <a-button
      type="primary"
      @click="open"
    >
      <template #icon>
        <plus-outlined />
      </template>
    </a-button>
    <basic-modal
      v-bind="bindSocialModelAttr"
      ref="basicFormRef"
    >
      <basic-form
        v-bind="socialPlansAttr"
        ref="basicFormRef"
      />
    </basic-modal>
  </a-input-group>
</template>
<script lang="ts" setup>
// 客户单位名称
import { companyApiSelectProps } from './props'
import CompanyApiAelect from './company-api-select.vue'

import { customerManage } from '@/api/customer'
import { BasicModalProps } from '@/components/modal/prop'
import { useMessage } from '@/hooks/message'
const props = defineProps(companyApiSelectProps)
const { apiParams } = props
import { useAppStore, useUserStore } from '@/store'
const { getReferenceItems } = useAppStore()
const visible = ref(false)
const basicFormRef = ref()
const datekey = ref()

// 模态框参数
const bindSocialModelAttr = computed((): BasicModalProps => ({
  title: '新增签约公司',
  width: 666,
  visible: unref(visible),
  okText: '提交',
  cancelText: '取消',
  okButtonProps: {
    type: 'primary'
  },
  destroyOnClose: true,
  onOk: async () => {
    await basicFormRef.value.validate()
    await customerManage.addContractSubject({
      ...basicFormRef.value.formModel
    })
    datekey.value = Date.now()
    visible.value = false
  },
  onCancel: () => {
    visible.value = false
  },
}))
// 模态框社保表格参数
const socialPlansAttr = computed((): IForm.Props => ({
  actionable: false,
  labelAlign: 'left',
  layout: 'vertical',
  schemas: [
    {
      component: 'Input',
      field: 'name',
      span: 12,
      label: '签约公司名称',
      componentProps: {
        placeholder: '请输入跟员工签署劳动合同的公司名称'
      },
      required: true,
    } as IForm.Schema<'Input'>,
    {
      component: 'Input',
      field: 'socialCode',
      span: 12,
      label: '统一社会信用代码',
      required: true,
    } as IForm.Schema<'Input'>,
    {
      component: 'Input',
      field: 'registerAddress',
      span: 12,
      label: '注册所在地',
    } as IForm.Schema<'Input'>,
    {
      component: 'Input',
      field: 'depositBank',
      span: 12,
      label: '开户银行',
    } as IForm.Schema<'Input'>,
    {
      component: 'InputNumber',
      field: 'depositAccount',
      span: 12,
      label: '开户账号',
      componentProps: {
        controls: false,
      },
    } as IForm.Schema<'InputNumber'>,
    {
      component: 'InputMobile',
      field: 'businessRegisterPhone',
      span: 12,
      label: '注册电话',
      componentProps: {
        phone: true,
        controls: false,
      },
    } as IForm.Schema<'InputMobile'>,
    {
      component: 'Input',
      field: 'businessRegisterAddress',
      span: 24,
      label: '注册地址',
    } as IForm.Schema<'Input'>,
    {
      label: '状态',
      field: 'isValid',
      component: 'RadioGroup',
      componentProps: {
        options: getReferenceItems('isValidEnum'),
      },
      defaultValue: 1,
    },
  ],
}))


function open() {
  if (!useUserStore().hasPermission('CONTRACT_COMPANY_CREATE')) {
    return useMessage.error('没有添加签约公司权限')
  }
  visible.value = true
}
</script>
