<template>
  <a-tree-select v-bind="bindAttrs" />
</template>
<script lang="ts" setup>
import { get, isArray } from 'lodash-es'
import { computed, PropType, unref, useAttrs } from 'vue'
import { useAppStore } from '@/store'
interface OptionItem {
    label: string;
    value: number | string;
    parentId: number;
    depth: number;
    children?: OptionItem[];
  }
const { reference } = useAppStore()
const props = defineProps({
  value: [Number, String, Array] as PropType<any>,
  referenceKey: String as PropType<any>,
  filter: {
    type: Object as PropType<Recordable>,
  },
  parentValue: {
    type: [String, Number] as PropType<string | number>,
  },
  valueField: {
    type: String as PropType<string>,
    default: 'value',
  },
  labelField: {
    type: String as PropType<string>,
    default: 'label',
  },
  parentField: {
    type: String as PropType<string>,
    default: 'parentId',
  },
  primaryKeyField: {
    type: String as PropType<string>,
    default: 'id',
  },
  depthField: {
    type: String as PropType<string>,
    default: 'depth',
  },
  zeroToUndefined: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  zeroValueToUndefined: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  numberToString: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})
const emits = defineEmits(['blur', 'change', 'select', 'update:value'])
const attrs = useAttrs()
if (props.value === 0 && props.zeroValueToUndefined) {
  emits('update:value', undefined)
}
// const value = computed(() => (props.value === 0 && props.zeroToUndefined ? undefined : props.value));
const value = computed(() => {
  if (props.value === 0 && props.zeroToUndefined) {
    return undefined
  } else {
    return props.value
  }
})

const getOptions = computed(() => {
  const { filter, referenceKey, parentValue, primaryKeyField, valueField, labelField, parentField, depthField, numberToString } = props
  let rawOptions = toRaw(unref(attrs.options ?? get(reference, referenceKey!))) as unknown as any
  if (value.value) {
    const selectedOption = rawOptions?.find(r => r[primaryKeyField] === value)
    if (selectedOption) {
      return rawObjectToOption(selectedOption)
    }
  }
  const filterRawOptions = filter
    ? rawOptions?.filter(item => Object.keys(filter).reduce((bool, key) => bool && item[key] === filter[key], true))
    : rawOptions

  const filterOptions: OptionItem[] = filterRawOptions.map(item => rawObjectToOption(item))
  const parentOption = rawOptions?.find(r => r[primaryKeyField] === parentValue)
  const options = tree(filterOptions, rawObjectToOption(parentOption))
  return isArray(options) ? options : [options]
})

function rawObjectToOption(rawObject?: any): OptionItem | undefined {
  if (!rawObject) {
    return undefined
  }
  const { labelField, parentField, depthField, numberToString, primaryKeyField } = props
  const value = rawObject[primaryKeyField]
  return {
    label: rawObject[labelField],
    value: numberToString ? `${value}` : value,
    parentId: rawObject[parentField],
    // depth: rawObject[depthField],
    depth: rawObject?.path ? rawObject?.path.split(',').length - 1 : rawObject?.level,
  }
}

function tree(rawOptions: OptionItem[], parentOption?: OptionItem) {
  let options: OptionItem[]
  if (!parentOption) {
    const minDepth = Math.min(...rawOptions.map(r => r.depth))
    options = rawOptions.filter(r => r.depth === minDepth)
    return options.map(item => tree(rawOptions, item))
  } else {
    options = rawOptions.filter(r => r.parentId === parentOption.value)
    if (!options || options.length <= 0) {
      return parentOption
    }
    const children = options.map(item => tree(rawOptions, item))
    return {
      ...parentOption,
      children,
    }
  }
}

function onUpdate(value) {
  emits('change', value)
  emits('select', value)
  emits('update:value', value)
}

const bindAttrs = computed(() => ({
  treeLine: true,
  dropdownStyle: { maxHeight: '280px' },
  treeNodeFilterProp: 'label',
  showSearch: true,
  ...attrs,
  value: unref(value),
  treeData: unref(getOptions),
  onChange: onUpdate,
  'onUpdate:value': onUpdate,
}))
</script>
