import type { InputProps } from 'ant-design-vue/es/input'
import { ExtractPropTypes } from 'vue'

export const inputSuffixProps = {
  // 双向数据绑定的该组件的值
  value: {
    type: [Number, String],
    // ?? 为什么不可用有 default
  },
  addonBefore: {
    type: Array,
    default: []
  },
  addonAfter: {
    type: Array,
    default: [
      {
        label: '日',
        value: 1
      },
      {
        label: '月',
        value: 2
      },
    ]
  },
}

export type InputSuffixProps = Partial<ExtractPropTypes<typeof inputSuffixProps>> & InputProps