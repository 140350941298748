<template>
  <div class="p-1 border border-gray-300 border-solid rounded-sm">
    <div class="flex items-start justify-between my-2">
      <div>
        {{ helpTextPrefix }} <span
          v-if="isFunction(demoUrl)"
          class="text-red-500 cursor-pointer"
          @click="demoUrl"
        >{{ helpText }} </span><template v-else>
          <a
            v-if="demoUrl"
            :href="demoUrl"
            target="blank"
            @click=""
          >{{ helpText }} </a>
        </template> 上传文件，支持xls,xlsx格式，单个文件不超过{{ maxSize }}MB
      </div>
      <Upload
        v-bind="$attrs"
        :limit-num="1"
        accept-type="file"
        :accept="accept"
        :max-size="maxSize"
        :show-upload-list="false"
        @change="handleChange"
      >
        <a-button type="primary">
          选择文件
        </a-button>
      </Upload>
    </div>
    <a-table
      v-if="tableDataSource.length > 0"
      row-key="序号"
      size="small"
      :bordered="false"
      :columns="tableColumns"
      :data-source="tableDataSource"
      :sticky="true"
      :pagination="false"
      :scroll="scroll"
    />
  </div>
</template>

<script lang="ts" setup>
import { ColumnProps } from 'ant-design-vue/es/table/Column'
import { ref, watch } from 'vue'
import Upload from './Upload.vue'
import { useMessage } from '@/hooks/message'
import { getExcelData } from '@/utils/xlsx'
import { numberToDate } from '@/utils/date'
import { isFunction } from 'lodash-es'
import { exportTableSelectProps } from './props'
// const requireField = ['realname', 'mobile', 'idcard', 'bank_account', 'should_money', 'money', 'shebao_money', 'gjj_money', 'tax_money', 'entry_at'];
// const fieldMap = {
//   realname: '姓名',
//   mobile: '电话',
//   idcard: '身份证',
//   bank_account: '银行卡',
//   should_money: '应发金额',
//   money: '实发金额',
//   shebao_money: '社保',
//   gjj_money: '公积金',
//   tax_money: '个税',
//   entry_at: '入职日期',
//   bank_name: '支行',
//   bank_sn: '联行号',
//   remark: '备注',
// };
const fieldMapFormat = {
  entry_at: value => {
    if (isNaN(value)) {
      return value
    }
    return numberToDate(value)
  },
  idcard: value => value.toString().replace(' ', ''),
  bank_account: value => value.toString().replace(' ', '')
}
const maxSize = 2 // MB
const accept = ['xls', 'xlsx']

const emits = defineEmits(['change', 'blue', 'update:value'])
const tableColumns = ref<ColumnProps[]>([])
const tableDataSource = ref<Recordable[]>([])
const props = defineProps(exportTableSelectProps)
console.log('props', props)

watch(
  () => props.value,
  async () => {

    if (props.value) {
      if (props.value?.details) {
        const [{ headers, results }] = await getExcelData(props.value?.file)
        console.log(headers)
        if (!checkHeader(headers)) {
          return false
        }
        filterHeader(headers)
        filterResult(results)
      }
    } else {
      tableDataSource.value = []
    }
  }
  , { immediate: true }
)

async function handleChange(res) {
  const { fileIds, file, status } = res
  if (status === 'done') {
    const [{ headers, results }] = await getExcelData(file.originFileObj)
    if (!checkHeader(headers)) {
      return false
    }
    filterHeader(headers)
    console.log(tableColumns)
    console.log(fileIds)
    const fileId = fileIds[fileIds.length - 1]
    const details = filterResult(results)
    console.log(123)

    emits('change', {
      fileId,
      details
    })
    emits('update:value', {
      fileId,
      details
    })
  }
  if (status === 'removed') {
  }
}

function checkHeader(header) {
  for (const k of props.requireField) {
    let flag = false
    for (const key of header) {
      if (key.indexOf(props.fieldMap[k]) >= 0) {
        flag = true
        break
      }
    }
    if (!flag) {
      useMessage.error('未识别出有效数据，请重新上传')
      return false
    }
  }

  return true
}

function filterHeader(header) {
  tableColumns.value = header.map(h => ({
    dataIndex: h,
    title: h,
    width: 80,
    ellipsis: true
  }))
}
function filterResult(results) {
  const source = results.filter(item => {
    for (const k of props.requireField) {
      let flag = false
      for (const key in item) {
        if (key.indexOf(props.fieldMap[k]) >= 0) {
          flag = true
          break
        }
      }
      if (!flag) {
        useMessage.error(`${props.fieldMap[k]}为必填，请修改重新上传`)
        return false
      }
    }
    return true
  })

  source.forEach(item => {
    for (const k in fieldMapFormat) {
      for (const key in item) {
        if (key.indexOf(props.fieldMap[k]) >= 0) {
          item[key] = fieldMapFormat[k](item[key])
        }
      }
    }
  })
  console.log('source', source)

  tableDataSource.value = source
  return source.map(item => {
    let r = {}
    for (const k in props.fieldMap) {
      r[k] = ''
      for (const key in item) {
        if (key.indexOf(props.fieldMap[k]) >= 0) {
          r[k] = item[key]
          break
        }
      }
    }
    return r
  })
}
</script>
