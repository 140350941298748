import { TableProps } from 'ant-design-vue/es/table'
import { ExtractPropTypes, PropType } from 'vue'

export const dynamicFormProps = {

  /** 双向数据绑定的该组件的值 */
  value: {
    type: Array as PropType<Recordable[]>,
    default: undefined,
  },

  dynamicValidateForm: {
    type: Array as PropType<IForm.Schema[]>,
    default: () => [],
  },
  formProps: {
    type: Object as PropType<IForm.Props>,
    required: true,
  },
  isTableMode: {
    type: Boolean,
    default: false,
  },
  tableProps: {
    type: Object as PropType<TableProps>,
    default: () => ({})
  },
  // 是否需要上下箭头
  needUpDown: {
    type: Boolean,
    default: true
  },
  // 是否需要加号
  needAdd: {
    type: Boolean,
    default: true
  },
  // 是否需要减号
  needDel: {
    type: Boolean,
    default: true
  },
  // 是否需要添加项
  needPlus: {
    type: Boolean,
    default: true
  }
}

export type DynamicFormProps = Partial<ExtractPropTypes<typeof dynamicFormProps>>