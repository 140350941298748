import { redirectRouter } from '@/hooks/router-redirect'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
import { attenanceRoutes } from './attendance'
const workerChildrenRoutes: CustomRouteRecordRaw[] = [
  {
    path: 'employment',
    name: 'employment',
    component: EmptyLayout,
    meta: {
      title: '员工管理',
      iconfont: 'icon-contacts-book-line',
      requiredPermission: 'EMPLOYMENT',
    },
    children: [
      {
        path: 'worker-to-be-employed',
        name: 'workerToBeEmployed',
        component: () => import('@/views/worker/employment/worker-to-be-employed.vue'),
        meta: {
          title: '入职管理',
          iconfont: 'icon-user-add-line',
          requiredPermission: 'WORKER_TO_BE_EMPLOYED',
          showBadge: 'toBeEmployedUsersCount',
        }
      },
      // 员工花名册
      {
        path: 'worker-list',
        name: 'workerList',
        component: () => import('@/views/worker/employment/worker-list.vue'),
        meta: {
          title: '员工档案列表',
          iconfont: 'icon-contacts-book-line',
          requiredPermission: 'WORKER_LIST',
        },
      },
      // 创建/编辑员工
      {
        path: 'worker-create',
        name: 'workerCreate',
        component: () => import('@/views/worker/employment/worker-create.vue'),
        meta: {
          hideInMenu: true,
          // actionRouterName: 'workerList',
          parentNameForBreadcrumb: 'workerList',
          title: '新增员工',
          keepAlive: true
        },
      },
      {
        path: 'worker-edit/:workerId/:projectId',
        name: 'workerEdit',
        component: () => import('@/views/worker/employment/worker-edit.vue'),
        meta: {
          hideInMenu: true,
          // actionRouterName: 'workerList',
          parentNameForBreadcrumb: 'workerList',
          title: '编辑员工',
          keepAlive: true
        },
      },
      // 员工详情
      {
        path: 'worker-detail/:id',
        name: 'workerDetail',
        component: () => import('@/views/worker/employment/worker-detail.vue'),
        meta: {
          title: '员工详情',
          hideInMenu: true,
          keepAlive: true,
          // actionRouterName: 'workerList',
          parentNameForBreadcrumb: 'workerList'
          // requiredPermission: 'ADMIN_VIEW',
        },
      },
      // 新增员工(零工项目)
      {
        path: 'odd-job-worker-create',
        name: 'oddJobWorkerCreate',
        component: () => import('@/views/worker/employment/odd-job-worker-create.vue'),
        meta: {
          hideInMenu: true,
          parentNameForBreadcrumb: 'workerList',
          title: '新增员工',
          keepAlive: true
        },
      },
      // 编辑员工(零工项目)
      {
        path: 'odd-job-worker-edit/:workerId',
        name: 'oddJobWorkerEdit',
        component: () => import('@/views/worker/employment/odd-job-worker-edit.vue'),
        meta: {
          hideInMenu: true,
          parentNameForBreadcrumb: 'workerList',
          title: '编辑员工',
          keepAlive: true
        },
      },
      // 员工详情(零工项目)
      {
        path: 'odd-job-worker-detail/:id',
        name: 'oddJobWorkerDetail',
        component: () => import('@/views/worker/employment/odd-job-worker-detail.vue'),
        meta: {
          title: '员工详情',
          hideInMenu: true,
          keepAlive: true,
          parentNameForBreadcrumb: 'workerList'
        },
      },

      {
        path: 'worker-to-be-confirmed',
        name: 'workerToBeConfirmed',
        component: () => import('@/views/worker/employment/worker-to-be-confirmed.vue'),
        meta: {
          title: '转正管理',
          iconfont: 'icon-user-2-line',
          requiredPermission: 'WORKER_TO_BE_CONFIRMED',
          showBadge: 'toBeConfirmedUsersCount',
        }
      },
      {
        path: 'worker-to-be-change',
        name: 'workerToBeChange',
        component: () => import('@/views/worker/employment/worker-to-be-change.vue'),
        meta: {
          title: '异动管理',
          iconfont: 'icon-user-2-line',
          requiredPermission: 'WORKER_TO_BE_CHSNGE',
        }
      },

      {
        path: 'contract-to-be-expired',
        name: 'contractToBeExpired',
        component: () => import('@/views/worker/employment/contract-to-be-expired.vue'),
        meta: {
          title: '合同即将到期列表',
          iconfont: 'icon-user-2-line',
          requiredPermission: 'CONTRACT_TO_BE_EXPIRED',
          showBadge: 'contractToBeExpiredCount',
        }
      },
      {
        path: 'idcard-to-be-expired',
        name: 'idcardToBeExpired',
        component: () => import('@/views/worker/employment/idcard-to-be-expired.vue'),
        meta: {
          title: '证件即将到期列表',
          iconfont: 'icon-user-2-line',
          showBadge: 'idcardToBeExpiredCount',
          requiredPermission: 'IDCARD_TO_BE_EXPIRED',
        }
      },
      {
        path: 'idcard-export',
        name: 'idcardExport',
        component: () => import('@/views/worker/employment/idcard-export.vue'),
        meta: {
          title: '导出证件数据',
          iconfont: 'icon-todo-line',
          requiredPermission: 'IDCARD_EXPORT',
        }
      },
      {
        path: 'leave-list',
        name: 'leaveList',
        component: () => import('@/views/worker/employment/leave-list.vue'),
        meta: {
          title: '离职管理',
          iconfont: 'icon-contacts-book-line',
          requiredPermission: 'LEAVE_LIST',
        },
      },
      {
        path: 'black-list',
        name: 'blackList',
        component: () => import('@/views/worker/employment/black-list.vue'),
        meta: {
          title: '黑名单列表',
          iconfont: 'icon-contacts-book-line',
          requiredPermission: 'BLACK_LIST',
        },
      },
    ]
  },
  {
    path: 'contract',
    name: 'contract',
    component: EmptyLayout,
    meta: {
      title: '合同管理',
      // hideInMenu: true,
      iconfont: 'icon-bar-chart-box-line',
      requiredPermission: 'CONTRACT',
    },
    children: [
      {
        path: 'contract-template-list',
        name: 'contractTemplateList',
        component: () => import('@/views/worker/contract/contract-template-list.vue'),
        meta: {
          title: '合同模板',
          iconfont: 'icon-file-list-3-line',
          requiredPermission: 'CONTRACT_TEMPLATE_LIST',
          keepAlive: true,
        }
      },
      {
        path: 'contract-list',
        name: 'contractList',
        component: () => import('@/views/worker/contract/contract-list.vue'),
        meta: {
          title: '合同签署列表',
          iconfont: 'icon-todo-line',
          requiredPermission: 'CONTRACT_LIST',
          showBadge: 'unsignedContractCount',
        }
      },

      {
        path: 'contract-template-detail/:templateId',
        name: 'contractTemplateDetail',
        component: () => import('@/views/worker/contract/contract-template-detail.vue'),
        meta: {
          title: '发起签署',
          hideInMenu: true,
          keepAlive: true,
          // actionRouterName: 'contractTemplateList',
          parentNameForBreadcrumb: 'contractTemplateList'
        }
      },
    ]
  },

  // 工资单
  {
    path: 'payroll',
    name: 'payroll',
    component: EmptyLayout,
    meta: {
      title: '工资单管理',
      iconfont: 'icon-contacts-book-line',
      requiredPermission: 'PAYROLL',
    },
    children: [
      {
        path: 'payroll-list',
        name: 'payrollList',
        component: () => import('@/views/worker/payroll/payroll-list.vue'),
        meta: {
          keepAlive: true,
          title: '工资单发送记录',
          iconfont: 'icon-bank-card-line',
          requiredPermission: 'PAYROLL_LIST',

        }
      },
      {
        path: 'payroll-feedback-list',
        name: 'payrollFeedbackList',
        component: () => import('@/views/worker/payroll/feedback-list.vue'),
        meta: {
          title: '工资单反馈',
          iconfont: 'icon-message-2-line',
          showBadge: 'wageFeedbackCount',
          requiredPermission: 'PAYROLL_FEEDBACK_LIST',
        }
      },
      {
        path: 'payroll-detail/:id',
        name: 'payrollDetail',
        component: () => import('@/views/worker/payroll/payroll-detail.vue'),
        meta: {
          title: '工资单详情',
          hideInMenu: true,
          parentNameForBreadcrumb: 'payrollList'
        }
      },
      {
        path: 'payroll-import',
        name: 'payrollImport',
        component: () => import('@/views/worker/payroll/payroll-import.vue'),
        meta: {
          title: '工资单创建',
          hideInMenu: true,
          parentNameForBreadcrumb: 'payrollList'
        }
      },
    ]
  },
  attenanceRoutes,
  // 其他
  {
    path: 'worker-other',
    name: 'workerOther',
    component: EmptyLayout,
    meta: {
      title: '其他',
      iconfont: 'icon-briefcase-line',
      requiredPermission: 'WORKER_OTHER',
    },
    children: [
      {
        path: 'job-list',
        name: 'jobList',
        component: () => import('@/views/worker/other/job-list.vue'),
        meta: {
          title: '岗位列表',
          iconfont: 'icon-database-2-line',
          requiredPermission: 'JOB_LIST',
        },
      },
    ]
  },
]
export const workerRoutes: CustomRouteRecordRaw = {
  path: 'worker',
  name: 'worker',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(workerChildrenRoutes),
  meta: {
    title: '人事管理',
    iconfont: 'icon-user-plus',
    desc: '以员工为维度内容',
    requiredPermission: 'WORKER',
    noQuick: true
  },
  children: workerChildrenRoutes
}