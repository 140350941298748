<template>
  <TreeSelect
    :dropdown-match-select-width="300"
    tree-default-expand-all
    v-bind="$attrs"
    :options="options"
    :parent-value="parentValue"
    show-checked-strategy="SHOW_ALL"
    value-field="id"
    label-field="title"
  />
</template>
<script lang="ts" setup>
import { globalApi } from '@/api/global'
import TreeSelect from './TreeSelect.vue'
const options = ref([])
onMounted(async () => {
  options.value = await globalApi.getCommercialInsuranceIndustry()
})

const parentValue = computed(() => undefined)
</script>
