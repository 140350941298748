
<style scoped>
:deep(.ant-menu-item-icon) {
  font-size: 18px;
}
</style>
<style lang="less" scoped>
.desc-box {
  border-bottom: 1px solid rgb(239 239 239);
  background-color: white;
}

.ant-layout-sider-collapsed {
  .desc-box {
    padding-left: 4px !important;
  }

  .desc-title {
    display: none !important;
  }
}

.desc-icon {
  height: 39px;
  width: 39px;
}
</style>

<script lang="tsx">
import { defineComponent, ref, h, computed } from 'vue'
import { useRouter, RouteRecordRaw } from 'vue-router'
import BasicIcon from '@/components/icon/basic-icon.vue'
import { listenerRouteChange } from '@/mitt/route-listener'
import * as antIcons from '@ant-design/icons-vue'
import { camelCase, upperFirst } from 'lodash-es'
import { useAppStore, useMenuTreeStore } from '@/store'
import { getItem, setItem, ROUTER_CACHE_SELECTKEY } from '@/utils/storage'
import { Model } from '@/types/model'
import { useSystemStore } from '../../../store/modules/system'
export function depthToArr(arr: object[], children = 'children', clone = true): any[] {
  let data: object[] = []
  arr.map(item => {
    data.push(clone ? { ...item } : item)
    if (Object(item)[children]) {
      data = data.concat(depthToArr(Object(item)[children], children))
    }
  })
  return data
}
export default defineComponent({
  emit: ['collapse', 'clicked'],
  components: { BasicIcon },
  props: {
    parentRoute: {
      type: Object
    }
  },
  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const menuTreeStore = useMenuTreeStore()
    const appStore = useAppStore()
    const systemStore = useSystemStore()
    const notificaitons = computed<Model.Notification>(() => appStore.notifications)

    // 获得菜单项配置
    const menuTree = menuTreeStore.menuTree
    // 监听路由变化，设置当前选中的菜单 key
    const selectedKey = ref<string[]>([]) // 当前选中的菜单项名
    const openKeys = ref<any[]>([])
    listenerRouteChange(newRoute => {
      if (!newRoute.meta.hideInMenu && newRoute.name) {
        selectedKey.value = [newRoute.name as string, ...(newRoute.meta.parentName || [])]
      } else if (newRoute.meta.parentNameForBreadcrumb) {
        let routerCache = getItem(ROUTER_CACHE_SELECTKEY)
        if (selectedKey.value.length === 0) {
          selectedKey.value = routerCache
        } else {
          setItem(ROUTER_CACHE_SELECTKEY, [newRoute.meta.parentNameForBreadcrumb as string, ...(newRoute.meta.parentName || [])])
        }
      }
    }, true)
    const openBySubMenuFn = () => {
      let find = menuTree.find(item => item.path === systemStore.selectedTopMenu && item.children)
      if (find) {
        openKeys.value = depthToArr(find.children ?? []).map(item => item.name)
      }
    }
    watch(() => systemStore.selectedTopMenu, () => {
      openBySubMenuFn()
    })

    const goRoute = elem => {
      if (elem?.targetHref) {
        window.open(elem?.targetHref)
        return
      }
      router.push({ name: elem?.name })
      emit('clicked')
    }

    // 渲染子菜单
    const renderSubMenu = () => {
      function travel(routeConfigList: RouteRecordRaw[], nodes = []) {
        if (routeConfigList) {
          routeConfigList.forEach(routeConfig => {
            const UpperIcon = upperFirst(camelCase(routeConfig.meta?.icon))

            const r = routeConfig?.children ? (
              <a-sub-menu
                key={routeConfig?.name}
                v-slots={{
                  icon: () => (routeConfig.meta?.iconfont ? h(BasicIcon, { name: routeConfig.meta.iconfont }) : h(antIcons[UpperIcon!])),
                  title: () => routeConfig.meta?.title || '',
                }}
              >
                {routeConfig?.children?.map(elem => (
                  <a-menu-item v-slots={{
                    icon: () => (elem.meta?.iconfont ? h(BasicIcon, { name: elem.meta.iconfont }) : h(antIcons[UpperIcon!])),

                  }} key={elem.name} onClick={() => (goRoute(elem))}>
                    {elem.meta?.title || ''}
                    {travel(elem?.children ?? [])}
                  </a-menu-item>
                ))}
              </a-sub-menu>
            ) : (
              <a-menu-item
                key={routeConfig.name}
                onClick={() => (goRoute(routeConfig))}
                v-slots={{
                  icon: () => (routeConfig.meta?.iconfont ? h(BasicIcon, { name: routeConfig.meta.iconfont }) : h(antIcons[UpperIcon!])),
                }}
              >
                {routeConfig.meta?.title}
                {routeConfig.meta?.showBadge && Object.prototype.hasOwnProperty.call(notificaitons.value, routeConfig.meta.showBadge)
                  ? <a-badge class="absolute mt-2 ml-1" size="large" count={notificaitons.value[routeConfig.meta.showBadge]} />
                  : ''
                }
              </a-menu-item>
            )
            nodes.push(r as never)
          })
        }
        return nodes
      }
      // console.log(selectedKey.value[2], selectedKey.value, 'selectedKey.value[2]')
      // // const subRouteConfigs = menuTree.filter(subRouteConfig => selectedKey.value[2] === subRouteConfig?.name)
      return travel(unref(currentRoutes))
    }

    const currentRoutes = computed(() => {
      let menuRes
      const subRouteConfigs = menuTree.filter(subRouteConfig => (props.parentRoute?.name ?? selectedKey.value[2]) === subRouteConfig?.name)
      if (systemStore.isPc) {
        if (subRouteConfigs.length > 0) {
          menuRes = subRouteConfigs[0].children!
        } else {
          menuRes = []
        }
      } else {
        menuRes = menuTree
      }

      return menuRes
    })

    let parentRoute = computed(() => (props.parentRoute ? props.parentRoute : route.matched![1]))
    return () => (
      <div style="height:100%">
        <div class="overflow-hidden" v-show={!!parentRoute.value}>
          <div class="relative py-6 pl-3 whitespace-nowrap desc-box">
            <span class="inline-block mr-2 rounded-md desc-icon bg-primaryColor-default">
              <BasicIcon name={parentRoute.value?.meta?.iconfont ?? 'icon-icon_vocation_default-10'} class="flex items-center justify-center w-full h-full text-2xl text-white" />
            </span>
            <span class="absolute inline-block -mb-2 text-sm whitespace-nowrap desc-title">
              {parentRoute.value?.meta?.title}<br />
              <span>{parentRoute.value?.meta?.desc}</span>
            </span>
          </div>
        </div>
        <a-menu
          selected-keys={selectedKey.value}
          open-keys={openKeys.value}
          style="height: calc(100% - 88px); overflow-y: auto; padding-bottom: 12px;"
          mode="inline"
        >
          {renderSubMenu()}
        </a-menu>
      </div>
    )
  },
})
</script>