<template>
  <div>
    <Cascader
      :value="value"
      :options="options"
      show-search
      :multiple="props.multiple"
      placeholder="请选择"
      change-on-select
      v-bind="$attrs"
      @update:value="handleChange"
    />
  </div>
</template>

<script lang="ts" setup name="region-picker">
import { ref } from 'vue'
import { Cascader } from 'ant-design-vue'
import { province, city, county } from './erpData'
import { regionSelectProps } from './props'
// const props = defineProps<{ value?: [] }>()
const props = defineProps(regionSelectProps)
const emits = defineEmits(['update:value'])

const options = ref(initOptions())
function initOptions() {
  return province.map(p => ({
    value: p.name,
    label: p.name,
    key: p.id,
    children: generateOptions(city, p.id)
  }))
}
function generateOptions(data, id) {
  if (!data[id]) {
    return []
  }
  return data[id].map(p => ({
    value: p.name,
    label: p.name,
    key: p.id,
    children: generateOptions(county, p.id)
  }))
}

// const loadData = selectedOptions => {
//   const length = selectedOptions.length
//   const targetOption = selectedOptions[length - 1]
//   let children = []
//   // eslint-disable-next-line default-case
//   switch (length) {
//     case 1:
//       children = city[targetOption.key]?.map(i => ({
//         value: i.name,
//         label: i.name,
//         key: i.id,
//         isLeaf: !county[i.id],
//       }))
//       break
//     case 2:
//       children = county[targetOption.key]?.map(i => ({
//         value: i.name,
//         label: i.name,
//         key: i.id,
//       }))
//       break
//   }
//   if (children && children.length > 0) {
//     targetOption.children = children
//   }
//   options.value = [...options.value]
// }

function handleChange(value) {
  emits('update:value', value)
}
</script>
