
import { ComputedRef, computed, unref } from 'vue'
import { isArray, isFunction, omit } from 'lodash-es'
import { RuleObject } from 'ant-design-vue/es/form'

// 创建默认消息提示
export function createDefaultMsg(schema: IForm.Schema, validateMassage = true) {
  let { component = 'Input', label } = schema
  if (component === 'DateRangePicker') {
    return ['请选择开始时间', '请选择结束时间']
  }
  let msg = ''
  switch (true) {
    case component.includes('Picker'):
    case component.includes('Select'):
    case component.includes('Cascader'):
    case component.includes('Checkbox'):
    case component.includes('Radio'):
    case component.includes('Switch'):
      msg = '请选择'
      break
    case component.includes('Upload'):
      msg = '请上传'
      break
    default:
      msg = '请输入'
      break
  }
  return validateMassage ? `${msg}${label}` : msg
}
// 创建表单配置规则
export function createSchemaRule(schema: IForm.Schema, formContext: IForm.Content) {
  let rules = schema.rules ?? []

  const { field } = schema

  if (schema.required && rules?.findIndex(rule => Reflect.get(rule, 'required')) < 0) {
    const validator: RuleObject = {
      trigger: 'blur',
      required: schema.required as boolean,
      message: createDefaultMsg(schema, true)
    }
    if (isArray(rules)) {
      // 获取请输入提示
      rules.push(validator)

    } else {
      rules = [rules, validator]
    }
  } else if (!schema.required) {
    rules = rules.filter(rules => !rules.required)
  }

  if (isArray(rules)) {
    rules = rules.map(item => {
      if (isFunction(item)) {
        item = item({
          formContext,
          schema,
          values: { ...formContext?.formModel },
          field,
          actions: omit(formContext, ['formModel'])
        })
      }
      return item
    }).filter(item => item)
  }
  if (schema.component === 'InputRange') {
    // 写入范围输入组件校验
    rules = [
      {
        required: schema.required as boolean,
        validator: (rule, value) => {
          if (!rule.required && (value === undefined
            || (Array.isArray(value) && value.length === 2 && !value[0] && !value[1]))) {
            return Promise.resolve()
          }

          if (Array.isArray(value) && value.length === 2) {
            const [start, end] = value

            if (isNaN(Number(start)) || isNaN(Number(end))) {
              return Promise.reject(new Error('请输入合法的数字数值'))
            }

            if ((!start && start !== 0) || (!end && end !== 0)) {
              return Promise.reject(new Error(`请输入完整的${schema.label}范围`))
            }

            if (Number(start) >= Number(end)) {
              return Promise.reject(new Error('请输入第一个小于第二个的数值'))
            }

            return Promise.resolve()
          }

          if (rule.required && value === undefined) {
            return Promise.reject(new Error(`请输入${schema.label}`))
          }

          return rule.required ? Promise.reject(new Error(`请输入完整的${schema.label}范围`)) : Promise.resolve()
        }

      }
    ]
  }
  return rules
}

export function useFormRules(propsRef: ComputedRef<IForm.Props>) {
  const getRules = computed(() => {
    const { schemas, rules } = unref(propsRef)
    let schemasRules = {}
    schemas
      ?.filter(schema => schema.required || schema.rules)
      .forEach(schema => {
        let schemaRules = schema.rules ?? []
        if (schema.required && schemaRules?.findIndex(rule => Reflect.get(rule, 'required')) < 0) {
          const validator = {
            required: schema.required,
            message: createDefaultMsg(schema, true)
          }
          if (isArray(schemaRules)) {
            schemaRules.push(validator)
          } else {
            schemaRules = [schemaRules, validator]
          }
        }
        schemasRules[schema.field!] = schemaRules
      })
    return {
      ...rules,
      ...schemasRules,
    }
  })

  return {
    getRules,
  }
}
